import React from "react";
import { motion } from "framer-motion";
import {
  FileSearch,
  LineChart,
  Workflow,
  ArrowRight,
  Shield,
  ChevronRight,
  Brain,
  Database,
  Bot,
  Map,
  FileCheck,
} from "lucide-react";
import TrustPartners from "./TrustPartners";

const HowItWorksSection = () => {
  const steps = [
    {
      icon: FileSearch,
      title: "Monitor",
      description:
        "Our AI constantly scans, analyzes, and assesses the impact of regulatory changes on your specific business context.",
      features: [
        "Track regulatory changes with AI precision",
        "Analyze impact on your business processes",
        "Identify risks before they trigger penalties",
        "Continuous monitoring of global compliance sources",
      ],
      color: "text-primary",
      bgColor: "bg-primary/10",
      borderColor: "border-primary/20",
      iconColor: "text-primary",
      accentColor: "border-primary",
    },
    {
      icon: Map,
      title: "Guide",
      description:
        "We provide strategic roadmaps and step-by-step guidance to address compliance requirements effectively.",
      features: [
        "Transform requirements into actionable roadmaps",
        "Generate step-by-step implementation plans",
        "Align compliance with business objectives",
        "Prioritize actions based on risk impact",
      ],
      color: "text-primary",
      bgColor: "bg-secondary-light/20",
      borderColor: "border-secondary-DEFAULT/30",
      iconColor: "text-secondary-dark",
      accentColor: "border-secondary-dark",
    },
    {
      icon: FileCheck,
      title: "Implement",
      description:
        "Deploy compliance processes with ready-to-use templates and controls that integrate with your existing systems.",
      features: [
        "Implement compliance processes with confidence",
        "Deploy ready-to-use policy & process templates",
        "Maintain continuous compliance monitoring",
        "Seamless integration with existing systems",
      ],
      color: "text-primary",
      bgColor: "bg-accent-DEFAULT/10",
      borderColor: "border-accent-DEFAULT/30",
      iconColor: "text-accent-accessible",
      accentColor: "border-accent-DEFAULT",
    },
  ];

  return (
    <section className="py-28 bg-gradient-to-b from-neutral-white to-white">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-bold text-primary">
            How <span className="text-accent-DEFAULT">LOKATIAL</span> Works
          </h2>
          <p className="text-xl mt-4 text-neutral-dark-gray max-w-3xl mx-auto">
            AI-accelerated regulatory intelligence and implementation that
            eliminates manual processes and information silos
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-12 relative z-10 mb-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative flex flex-col"
            >
              <div
                className={`p-6 rounded-xl border ${step.borderColor} ${step.bgColor}
                        transition-all duration-300 hover:shadow-lg hover:-translate-y-1 h-full flex flex-col bg-white`}
              >
                <div className="flex items-center mb-4">
                  <div className={`p-3 rounded-lg ${step.bgColor}`}>
                    <step.icon className={`h-6 w-6 ${step.iconColor}`} />
                  </div>
                  <h3 className={`text-xl font-bold ml-3 ${step.color}`}>
                    {step.title}
                  </h3>
                </div>

                <p className="text-neutral-dark-gray mb-4">
                  {step.description}
                </p>

                <div className="mt-auto relative">
                  {step.features.map((feature, i) => (
                    <div
                      key={i}
                      className="flex items-center mb-2 last:mb-0 pl-2"
                    >
                      <ChevronRight
                        className={`h-4 w-4 text-accent-DEFAULT mr-1.5 flex-shrink-0 text-primary`}
                      />
                      <span className="text-sm text-primary">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>

              {/* Fixed arrows positioning */}
              {index < steps.length - 1 && (
                <div className="hidden md:flex absolute right-[-20px] top-1/2 -translate-y-1/2 z-10 items-center justify-center">
                  <div className="bg-white rounded-full p-1 shadow-sm">
                    <ArrowRight className="h-6 w-6 text-accent-DEFAULT" />
                  </div>
                </div>
              )}
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="text-center"
        ></motion.div>

        {/* Trust Partners component */}
        <TrustPartners />
      </div>
    </section>
  );
};

export default HowItWorksSection;
