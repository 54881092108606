import React, { useState, forwardRef } from "react";
import {
  Bot,
  Brain,
  ChevronDown,
  ChevronUp,
  ShieldCheck,
  Clock,
  CheckCircle,
  Shield,
  Database,
  GitMerge,
  ArrowRight,
} from "lucide-react";
import { motion } from "framer-motion";

const DetailedUseCase = ({
  title,
  icon: Icon,
  challenge,
  howWeHelp,
  benefits,
  color,
  bgColor,
  borderColor,
  textColor,
  iconBgColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className={`mb-8 rounded-xl overflow-hidden border ${borderColor} hover:shadow-xl transition-all duration-300 hover:-translate-y-1`}
    >
      <div
        className={`flex items-center justify-between p-6 ${bgColor} cursor-pointer group`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <div className={`p-2 rounded-lg ${iconBgColor} mr-4 shadow-sm`}>
            <Icon className={`h-6 w-6 ${textColor}`} strokeWidth={2} />
          </div>
          <h3 className="text-xl font-bold text-primary">{title}</h3>
        </div>
        <div
          className={`p-2 rounded-full bg-white/50 group-hover:bg-white/70 transition-colors duration-200 shadow-sm`}
        >
          {isOpen ? (
            <ChevronUp className={`w-5 h-5 ${textColor}`} />
          ) : (
            <ChevronDown className={`w-5 h-5 ${textColor}`} />
          )}
        </div>
      </div>

      {isOpen && (
        <div className="p-6 text-neutral-dark-gray bg-white">
          <div className="space-y-6">
            <div>
              <h4 className="font-semibold mb-2 text-primary">Challenge:</h4>
              <p>{challenge}</p>
            </div>

            <div>
              <h4 className="font-semibold mb-2 text-primary">Solution:</h4>
              <div className="space-y-3">
                {howWeHelp.map((item, index) => (
                  <div key={index} className="flex items-start">
                    <CheckCircle
                      className={`w-5 h-5 ${textColor} mr-2 flex-shrink-0 mt-0.5`}
                    />
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <h4 className="font-semibold mb-3 text-primary">Key Benefits:</h4>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {benefits.map((benefit, index) => (
                  <div
                    key={index}
                    className={`p-3 rounded-lg ${bgColor}/80 flex items-center shadow-sm`}
                  >
                    <benefit.icon
                      className={`w-5 h-5 ${textColor} mr-2 flex-shrink-0`}
                    />
                    <span className="text-sm font-medium text-primary">
                      {benefit.text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

const UseCases = forwardRef((props, ref) => {
  const useCases = [
    {
      title: "Healthcare Professional Engagement Compliance",
      icon: CheckCircle,
      color: "primary",
      bgColor: "bg-primary/10",
      textColor: "text-primary",
      borderColor: "border-primary/20",
      iconBgColor: "bg-primary/20",
      challenge:
        "Pharmaceutical companies struggle with complex regulations for healthcare professional interactions from FDA, EMA, EFPIA, DOJ, and the Sunshine Act, creating friction that delays product launches and reduces commercial effectiveness.",
      howWeHelp: [
        "MONITOR: Specialized AI agents continuously scan regulatory sources and apply our Model Context Protocol to translate complex HCP engagement requirements from EFPIA and Sunshine Act into actionable intelligence",
        "ANALYZE: Our AI engine integrates with your CRM and expense systems to assess policy compliance with Anti-Kickback Statute without disrupting existing workflows",
        "GUIDE: Generate implementation roadmaps with step-by-step guidance for updating your documentation processes and system configurations",
        "Augment existing systems with compliance intelligence layers rather than replacing them, preserving your technology investments",
        "Deploy connector APIs that enable bidirectional data flow between Lokatial and your CRM, ERP, and document management systems for consistent compliance tracking",
      ],
      benefits: [
        { icon: Clock, text: "75% reduction in regulatory analysis time" },
        { icon: ShieldCheck, text: "90% faster impact assessment" },
        { icon: Database, text: "Zero disruption to existing systems" },
      ],
    },
    {
      title: "Third-Party Risk Management in Life Sciences",
      icon: Shield,
      color: "secondary-dark",
      bgColor: "bg-secondary-light/20",
      textColor: "text-secondary-dark",
      borderColor: "border-secondary-DEFAULT/30",
      iconBgColor: "bg-secondary-light/30",
      challenge:
        "Life sciences organizations struggle to monitor and manage compliance risks across large networks of vendors, creating friction that delays partnerships and increases time-to-market for critical products.",
      howWeHelp: [
        "MONITOR: Dedicated regulatory AI agents continuously track third-party risk requirements across healthcare jurisdictions and translate regulations into structured data using our Model Context Protocol",
        "ANALYZE: AI-powered risk assessment engine integrates with your contract management system to automatically analyze vendor documentation and identify compliance gaps",
        "GUIDE: Generate vendor-specific implementation roadmaps with clear action items that align compliance requirements with your business priorities",
        "Extend your existing contract and vendor management systems with compliance intelligence rather than replacing them",
        "Provide API-based integration with document repositories to enable real-time vendor compliance monitoring without workflow disruption",
      ],
      benefits: [
        { icon: Clock, text: "85% faster vendor risk assessment" },
        { icon: ShieldCheck, text: "Comprehensive regulatory coverage" },
        { icon: GitMerge, text: "Seamless integration with existing systems" },
      ],
    },
    {
      title: "Regulatory Change Management for FDA/EMA Requirements",
      icon: Bot,
      color: "accent-accessible",
      bgColor: "bg-accent-DEFAULT/10",
      textColor: "text-accent-accessible",
      borderColor: "border-accent-DEFAULT/30",
      iconBgColor: "bg-accent-DEFAULT/30",
      challenge:
        "Compliance teams at pharmaceutical companies are overwhelmed by regulatory changes, creating organizational friction as teams struggle to align policies with innovation timelines.",
      howWeHelp: [
        "MONITOR: Specialized regulatory AI agents continuously monitor FDA, EMA and global sources, using our Model Context Protocol to transform regulatory text into structured, actionable intelligence",
        "ANALYZE: Our AI engine integrates with your document management and ERP systems to assess the impact of regulatory changes on your existing processes and policies",
        "GUIDE: Generate tailored implementation roadmaps that integrate with your project management tools to align compliance deadlines with business objectives",
        "Augment your existing document management and reporting systems with intelligent compliance layers that preserve your technology investments",
        "Deploy bidirectional connectors between Lokatial and your training, HR, and quality management systems to ensure consistent regulatory implementation",
      ],
      benefits: [
        { icon: Brain, text: "Turn regulations into business enablers" },
        {
          icon: ShieldCheck,
          text: "Reduce compliance-growth conflicts by 95%",
        },
        { icon: Database, text: "Enhance existing systems, not replace them" },
      ],
    },
  ];

  const typeformUrl = "https://adi18k4liww.typeform.com/to/DYf77usz";

  return (
    <section
      ref={ref}
      className="py-28 bg-gradient-to-b from-white to-neutral-white"
    >
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <div className="text-center mb-16">
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-secondary-light/20 text-primary mb-4">
              <Brain className="w-4 h-4 mr-2 text-accent-DEFAULT" />
              Lokatial In Action
            </span>
            <h2 className="text-4xl font-bold text-primary mb-6">
              <span className="text-accent-DEFAULT">Transform</span> Your
              Compliance Operations
            </h2>
            <p className="text-xl text-neutral-dark-gray max-w-3xl mx-auto">
              Discover how Lokatial can help organizations like yours streamline
              compliance processes
            </p>
          </div>
        </motion.div>

        <div className="space-y-6">
          {useCases.map((useCase, index) => (
            <DetailedUseCase key={index} {...useCase} />
          ))}
        </div>

        {/* Demo Request Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="text-center mt-20 py-10 px-6 bg-gradient-to-r from-primary/5 via-white to-primary/5 rounded-xl"
        >
          <h3 className="text-2xl font-bold text-primary mb-6">
            Ready to <span className="text-accent-DEFAULT">Streamline</span>{" "}
            Your Compliance?
          </h3>
          <motion.div
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
          >
            <a
              href={typeformUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center px-6 py-3
                 bg-[#FFBF49] hover:bg-[#F5AD30]
                 text-primary font-bold text-base
                 rounded-lg transition-all duration-300
                 border-2 border-[#FFBF49]
                 shadow-[0_4px_10px_rgba(255,191,73,0.4)]
                 hover:shadow-[0_6px_15px_rgba(255,191,73,0.6)]
                 hover:scale-105
                 highlight-pulse"
            >
              Request Your Demo
              <ArrowRight className="ml-2 h-5 w-5" />
            </a>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
});

UseCases.displayName = "UseCases";

export default UseCases;
