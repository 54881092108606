import React from "react";
import { motion } from "framer-motion";
import { Marquee } from "./ui/Marquee";

// Import partner logos
import necLogo from "../assets/partners/nec-logo.png";
import elevXlogo from "../assets/partners/elevx-logo.png";
import nvidiaLogo from "../assets/partners/nvidia-logo.png";
import microsoftLogo from "../assets/partners/microsoft-logo.png";

// Import team experience logos
// These are placeholder imports - replace with actual logos
import companyLogo1 from "../assets/experience/company1-logo.png"; // Replace with actual path
import companyLogo2 from "../assets/experience/company2-logo.png"; // Replace with actual path
import companyLogo3 from "../assets/experience/company3-logo.png"; // Replace with actual path
import companyLogo4 from "../assets/experience/company4-logo.png"; // Replace with actual path
import companyLogo5 from "../assets/experience/company5-logo.png"; // Replace with actual path
import companyLogo6 from "../assets/experience/company6-logo.png"; // Replace with actual path

const TrustPartners = () => {
  const partners = [
    {
      name: "NEC ElevX",
      logo: necLogo,
    },
    {
      name: "ElevX",
      logo: elevXlogo,
    },
    {
      name: "NVIDIA Inception",
      logo: nvidiaLogo,
    },
    {
      name: "Microsoft for Startups",
      logo: microsoftLogo,
    },
  ];

  return (
    <div className="mt-16 mb-4">
      {/* Partners section */}
      <motion.h3
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
        className="text-center text-2xl font-bold text-primary mb-8"
      >
        Backed by <span className="text-accent-DEFAULT">Industry Leaders</span>
      </motion.h3>

      <div className="relative overflow-hidden py-4">
        <div className="pointer-events-none absolute inset-y-0 left-0 w-1/6 bg-gradient-to-r from-neutral-white to-transparent z-10"></div>

        <Marquee pauseOnHover className="[--duration:35s] py-2" repeat={2}>
          {partners.map((partner, index) => (
            <div
              key={index}
              className="mx-6 flex flex-col items-center justify-center"
            >
              <div className="bg-white p-4 rounded-xl shadow-sm border border-primary/10 h-16 w-32 flex items-center justify-center">
                {partner.logo && (
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    className="max-h-10 max-w-full grayscale opacity-50"
                  />
                )}
              </div>
            </div>
          ))}
        </Marquee>

        <div className="pointer-events-none absolute inset-y-0 right-0 w-1/6 bg-gradient-to-l from-neutral-white to-transparent z-10"></div>
      </div>

      <div className="relative overflow-hidden py-4">
        <div className="pointer-events-none absolute inset-y-0 left-0 w-1/6 bg-gradient-to-r from-neutral-white to-transparent z-10"></div>

        <div className="pointer-events-none absolute inset-y-0 right-0 w-1/6 bg-gradient-to-l from-neutral-white to-transparent z-10"></div>
      </div>
    </div>
  );
};

export default TrustPartners;
