import React, { useState, useRef, useEffect } from "react";
import {
  Menu,
  X,
  Brain,
  Workflow,
  Bot,
  ArrowUp,
  Search,
  BarChart3,
  Map,
  FileCheck,
} from "lucide-react";
import HowItWorksSection from "./HowItWorksSection";
import UseCases from "./UseCases";
import TechnologyStrategy from "./TechnologyStrategy";
import Footer from "./Footer";
import Ripple from "./ui/ripple";
import { theme } from "../styles/theme";
import logo from "../assets/lokatial-logo.png";
import styled from "styled-components";

const LandingPageContainer = styled.div`
  min-h-screen relative;
`;

const NavBar = styled.header`
  background-color: ${theme.colors.background.main};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 50;
`;

const CTAButton = styled.button`
  background-color: ${theme.colors.accent.main};
  color: ${theme.colors.primary.main};
  font-weight: bold;
  padding: 0.75rem 2rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  border: 2px solid ${theme.colors.accent.main};
  box-shadow: 0 4px 10px rgba(255, 191, 73, 0.4);

  &:hover {
    background-color: ${theme.colors.accent.accessible};
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(255, 191, 73, 0.6);
  }
`;

const LandingPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  // Create refs
  const TechnologyStrategyRef = useRef(null);
  const useCasesSectionRef = useRef(null);

  const typeformUrl = "https://adi18k4liww.typeform.com/to/DYf77usz";
  const calendarUrl = "https://cal.com/tarunjot/15min";

  // Add the missing scrollToSection function
  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      const yOffset = -80; // Adjust offset as needed for your header height
      const element = ref.current;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 500);
      const scrollPosition = window.scrollY;

      // Define section positions
      if (TechnologyStrategyRef.current && useCasesSectionRef.current) {
        const TechnologyStrategyPosition =
          TechnologyStrategyRef.current.offsetTop - 200;
        const useCasesPosition = useCasesSectionRef.current.offsetTop - 200;

        // Set active section based on scroll position
        if (scrollPosition >= useCasesPosition) {
          setActiveSection("Use Cases");
        } else if (scrollPosition >= TechnologyStrategyPosition) {
          setActiveSection("Features");
        } else {
          setActiveSection("");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Set up navigation items
  const navItems = [
    { label: "Features", action: () => scrollToSection(TechnologyStrategyRef) },
    { label: "Use Cases", action: () => scrollToSection(useCasesSectionRef) },
    {
      label: "Contact",
      action: () => window.open(calendarUrl, "_blank"),
    },
    {
      label: "Get Early Access",
      action: () => {
        window.open(typeformUrl, "_blank");
      },
      isHighlighted: true, // Flag to identify this as the highlighted button
    },
  ];

  // Mobile navigation menu section
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <LandingPageContainer>
      <NavBar>
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex items-center justify-between h-20">
            <div className="flex items-center">
              <img src={logo} alt="Lokatial" className="h-10" />
            </div>

            {/* Desktop Menu */}
            <nav className="hidden md:flex items-center space-x-6">
              {navItems
                .filter((item) => !item.isHighlighted)
                .map((item, index) => (
                  <button
                    key={index}
                    onClick={item.action}
                    className={`text-primary hover:text-accent-DEFAULT transition duration-300 ${
                      activeSection === item.label ? "text-accent-DEFAULT" : ""
                    }`}
                  >
                    {item.label}
                  </button>
                ))}
            </nav>

            {/* Mobile Menu Button */}
            <div className="flex md:hidden">
              <button
                onClick={toggleMenu}
                className="text-primary focus:outline-none"
              >
                {isOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation - Update to filter out highlighted items */}
        {isOpen && (
          <div className="md:hidden bg-white py-4 px-6 shadow-lg">
            <nav className="flex flex-col space-y-4">
              {navItems
                .filter((item) => !item.isHighlighted)
                .map((item, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      item.action();
                      setIsOpen(false); // Close menu after clicking
                    }}
                    className={`text-left text-sm font-medium py-2 border-b border-gray-100 ${
                      activeSection === item.label
                        ? "text-accent-DEFAULT"
                        : "text-primary"
                    }`}
                  >
                    {item.label}
                  </button>
                ))}
            </nav>
          </div>
        )}
      </NavBar>

      {/* New Hero Section with updated tagline and content */}
      <div
        className="relative min-h-[90vh] overflow-hidden"
        style={{ background: "linear-gradient(to bottom, #331A30, #4A2545)" }}
      >
        <Ripple
          mainCircleSize={700}
          mainCircleOpacity={0.45}
          numCircles={10}
          customColors={{
            from: "rgba(255, 191, 73, 0.25)", // Amber/Gold with transparency
            to: "rgba(184, 196, 187, 0.25)", // Muted Sage with transparency
          }}
          className="z-0"
        />

        <div className="relative z-10 max-w-7xl mx-auto px-6 py-28 text-center">
          {/* Feature badges row with improved contrast */}
          <div className="flex flex-wrap items-center justify-center gap-4 mb-10">
            <div className="flex items-center bg-primary/30 backdrop-blur-sm rounded-full px-4 py-2 border border-secondary-light/40">
              <Brain className="h-6 w-6 text-secondary-light" />
              <span className="ml-2 text-sm font-medium text-secondary-light">
                AI-Powered
              </span>
            </div>

            <div className="flex items-center bg-primary/30 backdrop-blur-sm rounded-full px-4 py-2 border border-secondary-light/40">
              <Bot className="h-6 w-6 text-secondary-light" />
              <span className="ml-2 text-sm font-medium text-secondary-light">
                Impact Analysis
              </span>
            </div>

            <div className="flex items-center bg-primary/30 backdrop-blur-sm rounded-full px-4 py-2 border border-secondary-light/40">
              <FileCheck className="h-6 w-6 text-secondary-light" />
              <span className="ml-2 text-sm font-medium text-secondary-light">
                Implementation Companion
              </span>
            </div>
          </div>

          {/* Updated headline with improved contrast */}
          <div className="relative z-10">
            <h1 className="text-5xl md:text-6xl font-bold text-neutral-white mb-10 tracking-tight drop-shadow-lg">
              Transforming compliance
              <span className="block mt-2 text-accent-DEFAULT font-extrabold drop-shadow-md">
                from bottleneck to enabler
              </span>
            </h1>

            {/* Key points with white bullet styling */}
            <div className="max-w-3xl mx-auto space-y-6 text-lg mb-12">
              {[
                "Intelligent orchestration of regulatory workflows from monitoring to implementation",
                "Specialized AI agents that transform compliance from reactive to proactive",
                "Turn regulations into operational excellence",
              ].map((point, idx) => (
                <div key={idx} className="flex items-center space-x-3 group">
                  <div className="h-2 w-2 rounded-full bg-white group-hover:animate-pulse flex-shrink-0" />
                  <p className="text-neutral-white text-left group-hover:text-secondary-light transition-colors duration-300">
                    {point}
                  </p>
                </div>
              ))}
            </div>

            {/* Enhanced CTA button with better visibility - FIXED URL */}
            <div className="flex flex-col sm:flex-row gap-4 justify-center mb-16">
              {navItems
                .filter((item) => item.isHighlighted)
                .map((item, index) => (
                  <a
                    key={index}
                    href={typeformUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block"
                  >
                    <CTAButton className="highlight-pulse !visible">
                      {item.label}
                    </CTAButton>
                  </a>
                ))}
            </div>

            {/* Simplified Three Pillars */}
            <div className="flex flex-col md:flex-row justify-center items-center gap-6 mt-16">
              <div className="flex flex-col items-center p-4 text-center">
                <div className="bg-primary/30 backdrop-blur-sm rounded-full p-4 border border-secondary-light/40 mb-4">
                  <Search className="h-8 w-8 text-secondary-light" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-1">
                  Monitor
                </h3>
                <p className="text-gray-200">AI-driven regulatory tracking</p>
              </div>

              <div className="hidden md:block h-0.5 w-12 bg-accent-DEFAULT/50"></div>

              <div className="flex flex-col items-center p-4 text-center">
                <div className="bg-primary/30 backdrop-blur-sm rounded-full p-4 border border-secondary-light/40 mb-4">
                  <Map className="h-8 w-8 text-secondary-light" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-1">Guide</h3>
                <p className="text-gray-200">Strategic compliance roadmaps</p>
              </div>

              <div className="hidden md:block h-0.5 w-12 bg-accent-DEFAULT/50"></div>

              <div className="flex flex-col items-center p-4 text-center">
                <div className="bg-primary/30 backdrop-blur-sm rounded-full p-4 border border-secondary-light/40 mb-4">
                  <FileCheck className="h-8 w-8 text-secondary-light" />
                </div>
                <h3 className="text-xl font-semibold text-white mb-1">
                  Implement
                </h3>
                <p className="text-gray-200">Streamlined execution tools</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="bg-white overflow-hidden">
        <HowItWorksSection />
        <TechnologyStrategy ref={TechnologyStrategyRef} />
        <UseCases ref={useCasesSectionRef} />
      </main>

      {/* Enhanced Scroll to Top Button with better visibility */}
      <button
        onClick={scrollToTop}
        className={`fixed right-8 bottom-8 p-3 rounded-full bg-primary border-2 border-accent-DEFAULT text-white shadow-lg
                   transition-all duration-300 hover:bg-accent-DEFAULT hover:text-primary hover:scale-110 z-50
                   ${
                     showScrollTop
                       ? "opacity-100 translate-y-0"
                       : "opacity-0 translate-y-10 pointer-events-none"
                   }`}
        aria-label="Scroll to top"
      >
        <ArrowUp className="h-6 w-6" />
      </button>

      <Footer />
    </LandingPageContainer>
  );
};

export default LandingPage;
