// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

async function prepare() {
  if (process.env.NODE_ENV === 'development') {
    try {
      const { worker } = await import('./mocks/browser');
      
      // Initialize MSW with simpler configuration
      await worker.start({
        serviceWorker: {
          url: '/mockServiceWorker.js'
        },
        onUnhandledRequest: 'bypass' // Bypass all unhandled requests
      });

      console.log('🔧 MSW Initialized');
      console.log('📍 Auth requests will bypass MSW');
      
    } catch (error) {
      console.error('❌ Failed to start MSW:', error);
    }
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));

prepare().then(() => {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
}).catch(error => {
  console.error('Failed to start MSW:', error);
  // Still render the app even if MSW fails to start
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
});

// Report web vitals in development only
if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
} else {
  reportWebVitals();
}