import React from "react";
import { Mail, ArrowRight } from "lucide-react";
import logo from "../assets/logo.png"; // Adjust path based on your file structure

const Footer = () => {
  const typeformUrl = "https://adi18k4liww.typeform.com/to/DYf77usz";

  return (
    <footer className="bg-primary text-white">
      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-16">
        <div className="mb-12">
          {/* Branding & Description */}
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
            <div className="mb-6 md:mb-0">
              <div className="mb-1">
                <img src={logo} alt="Lokatial" className="h-12" />
              </div>
              <div className="text-sm text-accent-DEFAULT font-medium">
                Making Compliance Effortless
              </div>
            </div>

            <div className="flex items-center">
              <a
                href={typeformUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-5 py-2
                  bg-[#FFBF49] hover:bg-[#F5AD30]
                  text-primary font-bold text-sm
                  rounded-lg transition-all duration-300
                  border-2 border-[#FFBF49]
                  shadow-[0_0_12px_rgba(255,191,73,0.5)]
                  hover:shadow-[0_0_18px_rgba(255,191,73,0.7)]"
              >
                Contact Us
                <ArrowRight className="ml-2 h-4 w-4" />
              </a>
            </div>
          </div>

          <div className="border-t border-white/20 pt-6">
            <p className="text-white/80 max-w-2xl">
              AI-powered impact analysis and implementation companion for
              pharmaceutical and life sciences organizations.
            </p>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="pt-8 border-t border-white/10">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="flex flex-col md:flex-row items-center md:items-start gap-2 text-white/60 text-sm">
              <span>&copy; {new Date().getFullYear()} Lokatial, Inc.</span>
              <span className="hidden md:inline">•</span>
              <a
                href="mailto:hello@lokatial.app"
                className="text-white/60 hover:text-accent-DEFAULT transition-colors duration-200 flex items-center gap-1"
              >
                <Mail className="w-4 h-4" />
                hello@lokatial.app
              </a>
              <span className="hidden md:inline">•</span>
              <span>All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
