import React from "react";

const LoginPage = () => {
  return (
    <div>
      <h2>Login functionality is not available in this preview.</h2>
    </div>
  );
};

export default LoginPage;
