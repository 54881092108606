import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";

// Components
import LandingPage from "./components/LandingPage";
// For placeholder implementation of LoginPage
import LoginPage from "./components/LoginPage";

const ErrorFallback = ({ error }) => (
  <div role="alert" className="p-4 bg-red-50 border border-red-200 rounded-lg">
    <h2 className="text-lg font-semibold text-red-600 mb-2">
      Something went wrong
    </h2>
    <pre className="text-sm text-red-500 whitespace-pre-wrap">
      {error.message}
    </pre>
  </div>
);

function App() {
  return (
    <div className="min-h-screen flex flex-col m-0 p-0 overflow-x-hidden">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {/* Main Content Container */}
        <div className="flex-grow flex flex-col relative">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<LandingPage />} />

            {/* Login route with placeholder */}
            <Route path="/login" element={<LoginPage />} />

            {/* Fallback route */}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </div>

        {/* Toast Notifications */}
        <Toaster
          position="top-right"
          toastOptions={{
            className: "rounded-lg shadow-md",
            duration: 5000,
            style: {
              background: "#fff",
              color: "#363636",
            },
          }}
        />
      </ErrorBoundary>
    </div>
  );
}

export default App;
