import React from "react";
import { motion } from "framer-motion";
import {
  BrainCircuit,
  Shield,
  Database,
  Network,
  Bot,
  Puzzle,
  Settings,
  Users,
  FileCheck,
  ServerCrash,
  LineChart,
  FileText,
  GitBranch,
  BookOpen,
  Search,
  ClipboardCheck,
  Link,
  Building,
} from "lucide-react";
import { Card } from "./ui/Card";
import { Marquee } from "./ui/Marquee";

const TechnologyStrategy = React.forwardRef((props, ref) => {
  // Existing capabilities for the first carousel
  const capabilities = [
    {
      name: "Intelligent Agent Network",
      icon: Bot,
      color: "primary",
      bgColor: "bg-primary/10",
      textColor: "text-primary",
    },
    {
      name: "Enterprise Knowledge Graph",
      icon: Network,
      color: "secondary-dark",
      bgColor: "bg-secondary-light/20",
      textColor: "text-secondary-dark",
    },
    {
      name: "Model Context Protocol Integration",
      icon: BrainCircuit,
      color: "primary",
      bgColor: "bg-primary/10",
      textColor: "text-primary",
    },
    {
      name: "Foundation Model Flexibility",
      icon: Settings,
      color: "secondary-dark",
      bgColor: "bg-secondary-light/20",
      textColor: "text-secondary-dark",
    },
    {
      name: "Adaptive Integration Layer",
      icon: Database,
      color: "accent-accessible",
      bgColor: "bg-accent-DEFAULT/10",
      textColor: "text-accent-accessible",
    },
    {
      name: "Enterprise-Grade Security",
      icon: Shield,
      color: "primary",
      bgColor: "bg-primary/10",
      textColor: "text-primary",
    },
  ];

  // New platform features from a compliance user perspective
  const platformFeatures = [
    {
      name: "HCP Engagement",
      icon: Users,
      color: "primary",
      bgColor: "bg-primary/10",
      textColor: "text-primary",
    },
    {
      name: "Third Party Risk Management",
      icon: ServerCrash,
      color: "secondary-dark",
      bgColor: "bg-secondary-light/20",
      textColor: "text-secondary-dark",
    },
    {
      name: "Risk Management",
      icon: LineChart,
      color: "accent-accessible",
      bgColor: "bg-accent-DEFAULT/10",
      textColor: "text-accent-accessible",
    },
    {
      name: "Policy Companion",
      icon: FileText,
      color: "primary",
      bgColor: "bg-primary/10",
      textColor: "text-primary",
    },
    {
      name: "Process Design Companion",
      icon: GitBranch,
      color: "secondary-dark",
      bgColor: "bg-secondary-light/20",
      textColor: "text-secondary-dark",
    },
    {
      name: "Compliance Frameworks",
      icon: Building,
      color: "primary",
      bgColor: "bg-primary/10",
      textColor: "text-primary",
    },
    {
      name: "Regulatory Intelligence",
      icon: BookOpen,
      color: "secondary-dark",
      bgColor: "bg-secondary-light/20",
      textColor: "text-secondary-dark",
    },
    {
      name: "Questionnaire Automation",
      icon: ClipboardCheck,
      color: "accent-accessible",
      bgColor: "bg-accent-DEFAULT/10",
      textColor: "text-accent-accessible",
    },
    {
      name: "System Integrations",
      icon: Link,
      color: "primary",
      bgColor: "bg-primary/10",
      textColor: "text-primary",
    },
    {
      name: "Trust Center",
      icon: Shield,
      color: "secondary-dark",
      bgColor: "bg-secondary-light/20",
      textColor: "text-secondary-dark",
    },
    {
      name: "Implementation Tools",
      icon: FileCheck,
      color: "accent-accessible",
      bgColor: "bg-accent-DEFAULT/10",
      textColor: "text-accent-accessible",
    },
    {
      name: "Smart Search",
      icon: Search,
      color: "primary",
      bgColor: "bg-primary/10",
      textColor: "text-primary",
    },
  ];

  return (
    <section
      ref={ref}
      className="py-28 bg-gradient-to-b from-white to-neutral-white"
    >
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <motion.div
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-secondary-light/20 text-primary mb-4">
            <Puzzle className="w-4 h-4 mr-2 text-accent-DEFAULT" />
            Complete Compliance Acceleration Platform
          </span>

          <h2 className="text-4xl font-bold text-primary mb-6">
            Technology That
            <span className="text-accent-DEFAULT"> Reduces Friction</span>
          </h2>

          <p className="text-xl text-neutral-dark-gray max-w-3xl mx-auto">
            Our AI agents transform regulatory complexity into business agility
            by integrating with your existing systems, reducing technical debt
            instead of adding to it.
          </p>
        </motion.div>

        {/* First Carousel - Capability Names */}
        <div className="mb-16">
          <h3 className="text-2xl font-bold text-primary mb-8 text-center">
            <span className="text-accent-DEFAULT">Powerful</span> Capabilities
          </h3>

          <div className="relative mb-8">
            <div className="pointer-events-none absolute inset-y-0 left-0 w-1/6 bg-gradient-to-r from-white to-transparent z-10"></div>
            <div className="pointer-events-none absolute inset-y-0 right-0 w-1/6 bg-gradient-to-l from-white to-transparent z-10"></div>

            <Marquee pauseOnHover className="[--duration:30s] py-4" repeat={2}>
              {capabilities.map((capability, index) => (
                <div key={index} className="mx-4 px-2">
                  <motion.div
                    whileHover={{ y: -5 }}
                    className={`${
                      capability.bgColor
                    } px-5 py-3 rounded-lg border ${
                      index % 2 === 0
                        ? "border-primary/20"
                        : "border-secondary-DEFAULT/30"
                    } flex items-center shadow-sm`}
                  >
                    <capability.icon
                      className={`h-5 w-5 ${capability.textColor} mr-3`}
                    />
                    <span className={`font-medium ${capability.textColor}`}>
                      {capability.name}
                    </span>
                  </motion.div>
                </div>
              ))}
            </Marquee>
          </div>
        </div>

        {/* Second Carousel - Platform Features */}
        <div className="mb-16">
          <h3 className="text-2xl font-bold text-primary mb-8 text-center">
            <span className="text-accent-DEFAULT">Compliance</span> Features
          </h3>

          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 w-1/6 bg-gradient-to-r from-white to-transparent z-10"></div>
            <div className="pointer-events-none absolute inset-y-0 right-0 w-1/6 bg-gradient-to-l from-white to-transparent z-10"></div>

            <Marquee
              pauseOnHover
              reverse
              className="[--duration:40s] py-4"
              repeat={2}
            >
              {platformFeatures.map((feature, index) => (
                <div key={index} className="mx-4 px-2">
                  <motion.div
                    whileHover={{ y: -5 }}
                    className={`${
                      feature.bgColor
                    } px-5 py-3 rounded-lg border ${
                      index % 2 === 0
                        ? "border-primary/20"
                        : "border-secondary-DEFAULT/30"
                    } flex items-center shadow-sm`}
                  >
                    <feature.icon
                      className={`h-5 w-5 ${feature.textColor} mr-3`}
                    />
                    <span className={`font-medium ${feature.textColor}`}>
                      {feature.name}
                    </span>
                  </motion.div>
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
});

export default TechnologyStrategy;
