export const theme = {
  colors: {
    // Primary
    primary: {
      main: "#4A2545", // Deep Plum/Aubergine
    },

    // Secondary - Sage
    secondary: {
      light: "#D0D8D3", // Light Sage
      main: "#B8C4BB", // Muted Sage
      dark: "#9AA59D", // Deep Sage
    },

    // Accent - Amber/Gold
    accent: {
      main: "#FFBF49",
      accessible: "#F5AD30",
    },

    // Neutrals
    neutral: {
      white: "#F5F3F0", // Warm White
      lightGray: "#E0DCD8", // Light Gray
      mediumGray: "#9D9893", // Medium Gray
      darkGray: "#5D5852", // Dark Gray
      charcoal: "#3A3633", // Charcoal
    },

    // Application-specific
    text: {
      primary: "#3A3633", // Charcoal for main text
      secondary: "#5D5852", // Dark Gray for secondary text
      light: "#F5F3F0", // Warm White for text on dark backgrounds
    },

    background: {
      main: "#F5F3F0", // Warm White
      paper: "#FFFFFF", // Pure White for cards/paper elements
      secondary: "#D0D8D3", // Light Sage for secondary backgrounds
    },

    button: {
      primary: "#4A2545", // Deep Plum
      hover: "#5F3759", // Slightly lighter plum for hover
      secondary: "#B8C4BB", // Muted Sage
      accent: "#FFBF49", // Amber/Gold
    },
  },
};
